export const CONFIG = {
    ORBN_ADDRESS: "0xB1c9D42FA4bA691eFe21656A7e6953d999B990c4",
    ORBN_DECIMALS: 18,
    STAKING_CONTRACT: "0x6c07c5de6006572dcC2A59E52AC091bfA730Eb12", 
    UNISWAP_PAIR_ADDRESS: "0xcfb1621196b35d04953659e33176eb7dc298010e",
    UNISWAP_ROUTER_ADDRESS: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    CLAIM_CONTRACT: "0x2017A61EB8D9d17812a970A3287eB078eDA0BeD6"
}


// testnet config
// export const CONFIG = {
//     ORBN_ADDRESS: "0x1c903d85a7DfFa45b1A06F0D02c69092d180cB6e",
//     ORBN_DECIMALS: 18,
//     STAKING_CONTRACT: "0x5846d5F129A8F6232cB5AFe4a0F6e844bad0548D", //    0xFa221222591Dd66A2CF8B3d955E21fc8A6850436
//     UNISWAP_PAIR_ADDRESS: "0xcfb1621196b35d04953659e33176eb7dc298010e",
//     UNISWAP_ROUTER_ADDRESS: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
//     CLAIM_CONTRACT: "0xCD29dca88Ed13bEA5E084bA7Dd7e877c31E35572"
// }   

