import { Logo, LogoKangamoon } from "components/icons/index"
import { Box } from "../../../../node_modules/@mui/material/index"

const index = () => {
  return (
    <Box sx={{mt:'66px', float:'right'}}>  
      {/* <Logo /> */}
      <LogoKangamoon />
    </Box>
  )
}

export default index