export const HomeIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.1875 5.12499C1.07147 5.12499 0.960188 5.0789 0.878141 4.99685C0.796094 4.91481 0.75 4.80353 0.75 4.68749V1.18837C0.75 1.07234 0.796094 0.961058 0.878141 0.879011C0.960188 0.796963 1.07147 0.75087 1.1875 0.75087H4.6875C4.80353 0.75087 4.91481 0.796963 4.99686 0.879011C5.07891 0.961058 5.125 1.07234 5.125 1.18837V4.68749C5.125 4.80353 5.07891 4.91481 4.99686 4.99685C4.91481 5.0789 4.80353 5.12499 4.6875 5.12499H1.1875ZM7.3125 5.12499C7.19647 5.12499 7.08519 5.0789 7.00314 4.99685C6.92109 4.91481 6.875 4.80353 6.875 4.68749V1.18837C6.875 1.07234 6.92109 0.961058 7.00314 0.879011C7.08519 0.796963 7.19647 0.75087 7.3125 0.75087H10.8116C10.9277 0.75087 11.0389 0.796963 11.121 0.879011C11.203 0.961058 11.2491 1.07234 11.2491 1.18837V4.68749C11.2491 4.80353 11.203 4.91481 11.121 4.99685C11.0389 5.0789 10.9277 5.12499 10.8116 5.12499H7.3125ZM1.1875 11.25C1.07147 11.25 0.960188 11.2039 0.878141 11.1219C0.796094 11.0398 0.75 10.9285 0.75 10.8125V7.31249C0.75 7.19646 0.796094 7.08518 0.878141 7.00314C0.960188 6.92109 1.07147 6.87499 1.1875 6.87499H4.6875C4.80353 6.87499 4.91481 6.92109 4.99686 7.00314C5.07891 7.08518 5.125 7.19646 5.125 7.31249V10.8125C5.125 10.9285 5.07891 11.0398 4.99686 11.1219C4.91481 11.2039 4.80353 11.25 4.6875 11.25H1.1875ZM7.3125 11.25C7.19647 11.25 7.08519 11.2039 7.00314 11.1219C6.92109 11.0398 6.875 10.9285 6.875 10.8125V7.31249C6.875 7.19646 6.92109 7.08518 7.00314 7.00314C7.08519 6.92109 7.19647 6.87499 7.3125 6.87499H10.8116C10.9277 6.87499 11.0389 6.92109 11.121 7.00314C11.203 7.08518 11.2491 7.19646 11.2491 7.31249V10.8125C11.2491 10.9285 11.203 11.0398 11.121 11.1219C11.0389 11.2039 10.9277 11.25 10.8116 11.25H7.3125Z" fill="#EF9933"/>
        </svg>

    );
};

export const ChevronDown = () => {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.19618 6.75C4.81128 7.41667 3.84903 7.41667 3.46413 6.75L0.866049 2.25C0.481149 1.58333 0.962274 0.749999 1.73207 0.749999L6.92823 0.75C7.69803 0.75 8.17915 1.58333 7.79425 2.25L5.19618 6.75Z" fill="white"/>
        </svg>

    )
}

export const CornorRight = () => {
    return (
        <svg width="33" height="13" viewBox="0 0 33 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M0 1H32V13" stroke="#000515" />
        </svg>

    )
}

export const Divider = () => {
    return (
        <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.48502 17.448C1.86602 17.448 1.34402 17.245 0.917023 16.84C0.512023 16.435 0.309021 15.912 0.309021 15.272C0.309021 14.632 0.512023 14.109 0.917023 13.704C1.34402 13.299 1.86602 13.096 2.48502 13.096C3.12502 13.096 3.64804 13.299 4.05304 13.704C4.45804 14.109 4.66104 14.632 4.66104 15.272C4.66104 15.912 4.45804 16.435 4.05304 16.84C3.64804 17.245 3.12502 17.448 2.48502 17.448ZM2.48502 5.25601C1.86602 5.25601 1.34402 5.05301 0.917023 4.64801C0.512023 4.24301 0.309021 3.71999 0.309021 3.07999C0.309021 2.43999 0.512023 1.91699 0.917023 1.51199C1.34402 1.10699 1.86602 0.903992 2.48502 0.903992C3.12502 0.903992 3.64804 1.10699 4.05304 1.51199C4.45804 1.91699 4.66104 2.43999 4.66104 3.07999C4.66104 3.71999 4.45804 4.24301 4.05304 4.64801C3.64804 5.05301 3.12502 5.25601 2.48502 5.25601Z" fill="#000515" />
        </svg>

    )
}

export const USDT = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1_135)">
<path fillRule="evenodd" clipRule="evenodd" d="M11.5 23C5.14841 23 0 17.8516 0 11.5C0 5.14841 5.14841 0 11.5 0C17.8516 0 23 5.14841 23 11.5C23 17.8516 17.8516 23 11.5 23ZM12.8814 9.91372V8.21316H16.7728V5.61991H6.17766V8.21316H10.069V9.913C6.90647 10.0582 4.52813 10.6849 4.52813 11.4353C4.52813 12.1857 6.90647 12.8117 10.069 12.9576V18.4072H12.8814V12.9562C16.0389 12.811 18.4115 12.185 18.4115 11.4353C18.4115 10.6857 16.0389 10.0596 12.8814 9.91372ZM12.8814 12.494V12.4926C12.8024 12.4983 12.3948 12.5228 11.4856 12.5228C10.7597 12.5228 10.2487 12.5012 10.069 12.4926V12.4948C7.27447 12.3718 5.18866 11.8853 5.18866 11.3031C5.18866 10.7216 7.27447 10.235 10.069 10.1099V12.0103C10.2515 12.0232 10.7748 12.0542 11.4978 12.0542C12.3654 12.0542 12.8002 12.0182 12.8814 12.011V10.1114C15.6702 10.2357 17.751 10.7223 17.751 11.3031C17.751 11.8853 15.6702 12.3704 12.8814 12.494Z" fill="#57FC85"/>
</g>
<defs>
<clipPath id="clip0_1_135">
<rect width="23" height="23" fill="white"/>
</clipPath>
</defs>
</svg>


    )
}

export const ORBN = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="23" height="23" rx="11.5" fill="#1F2330"/>
<path d="M11.0918 9.97316L14.283 5.7644H18.7214L14.2812 11.5042L18.7232 17.2308H14.2676L11.0918 13.0369V9.97316Z" fill="white"/>
<path d="M7.97094 5.7644L11.1621 9.97316V13.0369L7.98629 17.2308H3.53074L7.97275 11.5042L3.53255 5.7644H7.97094Z" fill="#EF9933"/>
<rect x="5.67188" y="10.3452" width="5.49678" height="3.1586" fill="#1F2330"/>
</svg>


    )
}


export const Logo = () => {
    return (
        <svg width="156" height="26" viewBox="0 0 156 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.00383358 17.2429V24.22V25.533H7.67483V17.2429H20.1972C21.4968 17.2429 22.6699 17.0742 23.7126 16.7349C24.7975 16.3841 25.7348 15.8493 26.5188 15.1344C27.3239 14.4002 27.9257 13.484 28.3225 12.3876C28.7001 11.3449 28.8899 10.1488 28.8899 8.80514C28.8899 7.46146 28.7001 6.2673 28.3225 5.22265C27.9257 4.12433 27.3239 3.2081 26.5188 2.47589C25.7329 1.76093 24.7975 1.22806 23.7126 0.87537C22.6699 0.536098 21.4987 0.36742 20.1991 0.36742H0.00383358V10.4919H0L0.00383358 10.4938V10.4919H7.671V7.08961H19.5973C19.9346 7.08961 20.2126 7.12411 20.4272 7.19311C20.5998 7.24678 20.7378 7.32346 20.8432 7.4193C21.0483 7.60714 21.1518 8.06909 21.1518 8.80322C21.1518 9.21533 21.1115 9.55268 21.0291 9.81145L21.0349 9.81337C20.9831 9.98013 20.9141 10.1047 20.8317 10.1852C20.594 10.4057 20.1819 10.5168 19.5992 10.5168V10.5226L7.67292 10.4938V17.2429H0.00383358ZM61.9066 0.365503V18.807H69.5776V25.533H86.0639V18.807H69.5776V0.365503H61.9066ZM87.6261 0.365503V24.2181V24.22V25.533H111.783V18.807H95.2971V0.365503H87.6261ZM113.346 16.3113V25.5311H121.017V16.3113H113.346ZM113.346 0.365503V9.58527H121.017V0.365503H113.346ZM146.569 0.363586L139.792 9.6006V16.3247L146.537 25.5291H156L146.566 12.9607L155.996 0.363586H146.569ZM59.085 0.367455H51.414V0.369372V18.968H38.1211V25.6365H55.7498C57.5842 25.6365 59.085 24.1357 59.085 22.3013V18.9661V0.367455ZM30.452 0.367455V18.8089H38.123V0.367455H30.452Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M139.944 9.60079L133.166 0.36377H123.739L130.725 9.69479H139.944V9.60079ZM139.891 16.3974H130.59L123.735 25.5293H133.199L139.891 16.3974Z" fill="#EF9933"/>
        </svg>

    )
}

export const StakersIcon = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.80824 5.81791C8.34074 5.62926 7.65764 5.50001 6.87499 5.50001V4.40001C7.76269 4.40001 8.59209 4.54466 9.22019 4.79821C9.53259 4.92471 9.82189 5.08861 10.0424 5.29871C10.2635 5.51046 10.45 5.80581 10.45 6.17211C10.45 6.53896 10.2641 6.83376 10.0424 7.04551C9.82244 7.25616 9.53259 7.42006 9.22019 7.54601C8.59209 7.80011 7.76269 7.94421 6.87499 7.94421C5.98729 7.94421 5.15789 7.80011 4.52979 7.54656C4.21739 7.42006 3.92809 7.25616 3.70754 7.04606C3.48699 6.83321 3.29999 6.53841 3.29999 6.17156H4.39999L4.39944 6.16331C4.41581 6.19659 4.43906 6.22602 4.46764 6.24966C4.55344 6.33216 4.70689 6.43116 4.94229 6.52576C5.40924 6.71496 6.09179 6.84366 6.87499 6.84366C7.65764 6.84366 8.34074 6.71441 8.80824 6.52576C9.04309 6.43116 9.19654 6.33161 9.28234 6.24966C9.30778 6.22719 9.3295 6.20083 9.34669 6.17156C9.32948 6.1423 9.30777 6.11594 9.28234 6.09346C9.19654 6.01151 9.04309 5.91196 8.80824 5.81736M9.35164 6.18311L9.34999 6.18091C9.35047 6.18178 9.35084 6.1827 9.35109 6.18366M9.34999 6.16386C9.34999 6.16166 9.35054 6.16056 9.35109 6.16056L9.34999 6.16386Z" fill="#EF9933"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.84999 5.86685C3.99586 5.86685 4.13575 5.9248 4.2389 6.02794C4.34204 6.13109 4.39999 6.27098 4.39999 6.41685V8.12075C4.40439 8.13175 4.41924 8.15925 4.46764 8.206C4.55344 8.28795 4.70689 8.3875 4.94229 8.4821C5.40924 8.67075 6.09179 8.8 6.87499 8.8C7.65764 8.8 8.34074 8.67075 8.80824 8.4821C9.04309 8.38695 9.19654 8.28795 9.28234 8.206C9.31034 8.18238 9.33334 8.15339 9.34999 8.12075V6.41685C9.34999 6.27098 9.40793 6.13109 9.51108 6.02794C9.61422 5.9248 9.75412 5.86685 9.89999 5.86685C10.0459 5.86685 10.1858 5.9248 10.2889 6.02794C10.392 6.13109 10.45 6.27098 10.45 6.41685V8.12735C10.45 8.49475 10.2641 8.78955 10.0424 9.0013C9.82244 9.21195 9.53259 9.3753 9.22019 9.5018C8.59209 9.7559 7.76269 9.9 6.87499 9.9C5.98729 9.9 5.15789 9.7559 4.52979 9.5018C4.21739 9.3753 3.92809 9.21195 3.70754 9.0013C3.48699 8.789 3.29999 8.49475 3.29999 8.1279V6.41685C3.29999 6.27098 3.35793 6.13109 3.46108 6.02794C3.56422 5.9248 3.70412 5.86685 3.84999 5.86685ZM9.35164 8.11635L9.35054 8.1191C9.35054 8.11745 9.35109 8.11635 9.35164 8.11635Z" fill="#EF9933"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.05999 8.80001V7.70001H6.15999V8.80001H5.05999ZM7.47999 8.80001V7.70001H8.57999V8.80001H7.47999ZM1.64889 2.88366V2.88091C1.64903 2.88182 1.64903 2.88275 1.64889 2.88366ZM1.65329 2.87266C1.67041 2.84318 1.69214 2.81663 1.71764 2.79401C1.80344 2.71206 1.95689 2.61251 2.19229 2.51791C2.65924 2.32926 3.34179 2.20001 4.12499 2.20001C4.90764 2.20001 5.59074 2.32926 6.05824 2.51791C6.29309 2.61306 6.44654 2.71206 6.53234 2.79401C6.57249 2.83251 6.58954 2.85836 6.59669 2.87211C6.57949 2.90137 6.55777 2.92773 6.53234 2.95021C6.44654 3.03271 6.29309 3.13171 6.05769 3.22631C5.59074 3.41551 4.90819 3.54421 4.12499 3.54421C3.34234 3.54421 2.65924 3.41551 2.19229 3.22631C1.95689 3.13171 1.80344 3.03216 1.71764 2.95021C1.69218 2.92775 1.67046 2.90194 1.65329 2.87266ZM6.60164 2.88311L6.59999 2.88091C6.60047 2.88177 6.60084 2.8827 6.60109 2.88366M6.59999 2.86386C6.59999 2.86166 6.60054 2.86056 6.60109 2.86056L6.59999 2.86386ZM1.78034 1.49821C2.40789 1.24466 3.23729 1.10001 4.12499 1.10001C5.01269 1.10001 5.84209 1.24466 6.47019 1.49821C6.78259 1.62471 7.07189 1.78861 7.29244 1.99871C7.51354 2.21046 7.69999 2.50581 7.69999 2.87211C7.69999 3.23896 7.51409 3.53376 7.29189 3.74551C7.07189 3.95616 6.78259 4.12006 6.47019 4.24601C5.84209 4.50011 5.01269 4.64421 4.12499 4.64421C3.23729 4.64421 2.40789 4.50011 1.77979 4.24656C1.46739 4.12006 1.17809 3.95616 0.957538 3.74606C0.736988 3.53321 0.549988 3.23841 0.549988 2.87156C0.549988 2.50526 0.736438 2.20991 0.957538 1.99816C1.17754 1.78806 1.46739 1.62416 1.77979 1.49766" fill="#EF9933"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.09999 2.56685C1.24586 2.56685 1.38575 2.6248 1.4889 2.72794C1.59204 2.83109 1.64999 2.97098 1.64999 3.11685V4.82075C1.66663 4.85339 1.68963 4.88237 1.71764 4.906C1.80344 4.98795 1.95689 5.0875 2.19229 5.1821C2.65924 5.37075 3.34179 5.5 4.12499 5.5C4.90764 5.5 5.59074 5.37075 6.05824 5.1821C6.29309 5.08695 6.44654 4.98795 6.53234 4.906C6.56034 4.88237 6.58334 4.85339 6.59999 4.82075V3.11685C6.59999 2.97098 6.65793 2.83109 6.76108 2.72794C6.86422 2.6248 7.00412 2.56685 7.14999 2.56685C7.29586 2.56685 7.43575 2.6248 7.5389 2.72794C7.64204 2.83109 7.69999 2.97098 7.69999 3.11685V4.82735C7.69999 5.1942 7.51409 5.48955 7.29189 5.7013C7.07189 5.91195 6.78259 6.0753 6.47019 6.2018C5.84209 6.4559 5.01269 6.6 4.12499 6.6C3.23729 6.6 2.40789 6.4559 1.77979 6.2018C1.46739 6.0753 1.17809 5.91195 0.957538 5.7013C0.736988 5.489 0.549988 5.1942 0.549988 4.8279V3.11685C0.549988 2.97098 0.607934 2.83109 0.711079 2.72794C0.814224 2.6248 0.954119 2.56685 1.09999 2.56685ZM6.60164 4.81635L6.59999 4.8191C6.59999 4.81745 6.60054 4.81635 6.60109 4.81635" fill="#EF9933"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.31 5.50001V4.40001H3.41V5.50001H2.31ZM4.73 5.50001V4.40001H5.83V5.50001H4.73Z" fill="#EF9933"/>
        </svg>

    )
}


export const TvlIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.73819 3.51611H0.717102C0.505513 3.51611 0.333984 3.68764 0.333984 3.89923V9.11003C0.333984 9.32162 0.505513 9.49315 0.717102 9.49315H2.73819C2.94978 9.49315 3.12131 9.32162 3.12131 9.11003V3.89923C3.12131 3.68764 2.94978 3.51611 2.73819 3.51611Z" fill="#EF9933"/>
            <path d="M9.40811 2.49463H7.38702C7.17543 2.49463 7.00391 2.66616 7.00391 2.87775V9.11021C7.00391 9.32179 7.17543 9.49332 7.38702 9.49332H9.40811C9.6197 9.49332 9.79123 9.32179 9.79123 9.11021V2.87775C9.79123 2.66616 9.6197 2.49463 9.40811 2.49463Z" fill="#EF9933"/>
            <path d="M6.07218 0.451172H4.05109C3.8395 0.451172 3.66797 0.6227 3.66797 0.834289V9.11007C3.66797 9.32166 3.8395 9.49319 4.05109 9.49319H6.07218C6.28379 9.49319 6.45529 9.32166 6.45529 9.11007V0.83431C6.45529 0.622722 6.28376 0.451172 6.07218 0.451172Z" fill="#EF9933"/>
        </svg>

    )
}

export const LockIcon = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.50001 0.916656C6.1078 0.916656 6.69069 1.1581 7.12046 1.58787C7.55023 2.01764 7.79168 2.60054 7.79168 3.20832V4.58332C8.15635 4.58332 8.50609 4.72819 8.76395 4.98605C9.02181 5.24391 9.16668 5.59365 9.16668 5.95832V8.70832C9.16668 9.073 9.02181 9.42273 8.76395 9.68059C8.50609 9.93846 8.15635 10.0833 7.79168 10.0833H3.20834C2.84367 10.0833 2.49393 9.93846 2.23607 9.68059C1.97821 9.42273 1.83334 9.073 1.83334 8.70832V5.95832C1.83334 5.59365 1.97821 5.24391 2.23607 4.98605C2.49393 4.72819 2.84367 4.58332 3.20834 4.58332V3.20832C3.20834 2.60054 3.44979 2.01764 3.87956 1.58787C4.30933 1.1581 4.89222 0.916656 5.50001 0.916656ZM5.50001 6.41666C5.26875 6.41658 5.046 6.50393 4.87643 6.66118C4.70685 6.81843 4.60298 7.03396 4.58564 7.26457L4.58334 7.33332C4.58334 7.51462 4.6371 7.69185 4.73783 7.8426C4.83855 7.99334 4.98172 8.11083 5.14922 8.18021C5.31672 8.24959 5.50103 8.26775 5.67884 8.23238C5.85666 8.19701 6.01999 8.1097 6.14819 7.9815C6.27639 7.85331 6.36369 7.68997 6.39906 7.51216C6.43443 7.33434 6.41628 7.15003 6.3469 6.98253C6.27752 6.81503 6.16003 6.67187 6.00928 6.57114C5.85854 6.47042 5.68131 6.41666 5.50001 6.41666ZM5.50001 1.83332C5.13534 1.83332 4.7856 1.97819 4.52774 2.23605C4.26988 2.49391 4.12501 2.84365 4.12501 3.20832V4.58332H6.87501V3.20832C6.87501 2.84365 6.73014 2.49391 6.47228 2.23605C6.21442 1.97819 5.86468 1.83332 5.50001 1.83332Z" fill="#69738D"/>
        </svg>

    )
}

export const LogoSmall = () => {
    return (
        <svg width="54" height="9" viewBox="0 0 54 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.00130496 5.74573V8.12075V8.56769H2.61252V5.74573H6.87517C7.31755 5.74573 7.71686 5.68831 8.07181 5.57282C8.44111 5.45341 8.76018 5.27137 9.02704 5.028C9.30108 4.7781 9.50596 4.46622 9.64102 4.093C9.76956 3.73805 9.83416 3.3309 9.83416 2.87352C9.83416 2.41613 9.76956 2.00963 9.64102 1.65403C9.50596 1.28016 9.30108 0.968278 9.02704 0.719031C8.75952 0.475657 8.44111 0.294268 8.07181 0.174212C7.71686 0.0587231 7.3182 0.00130496 6.87582 0.00130496H0.00130496V3.4477H0L0.00130496 3.44835V3.4477H2.61122V2.28955H6.67094C6.78578 2.28955 6.88039 2.30129 6.95346 2.32478C7.01219 2.34305 7.05916 2.36915 7.09505 2.40177C7.16487 2.46572 7.2001 2.62296 7.2001 2.87286C7.2001 3.01315 7.1864 3.12798 7.15834 3.21607L7.1603 3.21672C7.14268 3.27348 7.11919 3.3159 7.09114 3.3433C7.01023 3.41833 6.86995 3.45618 6.67159 3.45618V3.45814L2.61187 3.44835V5.74573H0.00130496ZM21.0731 0.000652478V6.27815H23.6843V8.56769H29.2963V6.27815H23.6843V0.000652478H21.0731ZM29.8281 0.000652478V8.12009V8.12075V8.56769H38.0512V6.27815H32.4393V0.000652478H29.8281ZM38.583 5.42862V8.56704H41.1942V5.42862H38.583ZM38.583 0.000652478V3.13907H41.1942V0.000652478H38.583ZM49.8924 0L47.5853 3.14429V5.43319L49.8813 8.56639H53.1026L49.8911 4.28809L53.1013 0H49.8924ZM20.1123 0.00132969H17.5011V0.00198217V6.33298H12.9762V8.60295H18.977C19.6015 8.60295 20.1123 8.09206 20.1123 7.46764V6.33233V0.00132969ZM10.3656 0.00132969V6.27883H12.9768V0.00132969H10.3656Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M47.6385 3.14454L45.3313 0.000244141H42.1224L44.5003 3.17655H47.6385V3.14454ZM47.6204 5.45812H44.4545L42.1211 8.56664H45.3424L47.6204 5.45812Z" fill="#FF8A00"/>
        </svg>


    )
}


export const LogoSmallKangamoon = () => {
    return (
        <a href="https://kangamoon.com" className="page-ath-logo"><img width="54" height="30" className="page-ath-logo-img" src="https://kangamoon.game/wp-content/uploads/2024/01/kanga-logo.svg" srcSet="https://kangamoon.game/wp-content/uploads/2024/01/kanga-logo.svg" alt="Kangamoon"/></a>

    )
}

export const LogoKangamoon = () => {
    return (
        <a href="https://kangamoon.com" className="page-ath-logo"><img  className="page-ath-logo-img" src="https://kangamoon.game/wp-content/uploads/2024/01/kanga-logo.svg" srcSet="https://kangamoon.game/wp-content/uploads/2024/01/kanga-logo.svg" alt="Kangamoon"/></a>

    )
}
            

export const EnFlag = () => {
    return (
        <svg width="33" height="23" viewBox="0 0 33 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_574)">
            <path d="M33 0.5214H0V22.4788H33V0.5214Z" fill="#F0F0F0"/>
            <path d="M18.5625 0.521233H14.4375V9.44127H0V13.5582H14.4375V22.4783H18.5625V13.5582H33V9.44127H18.5625V0.521233Z" fill="#D80027"/>
            <path d="M25.3807 15.3183L33.0001 19.5431V15.3183H25.3807Z" fill="#0052B4"/>
            <path d="M20.0869 15.3183L33 22.4782V20.4536L23.7385 15.3183H20.0869Z" fill="#0052B4"/>
            <path d="M29.5604 22.4782L20.0869 17.225V22.4782H29.5604Z" fill="#0052B4"/>
            <path d="M20.0869 15.3183L33 22.4782V20.4536L23.7385 15.3183H20.0869Z" fill="#F0F0F0"/>
            <path d="M20.0869 15.3183L33 22.4782V20.4536L23.7385 15.3183H20.0869Z" fill="#D80027"/>
            <path d="M5.82276 15.3183L0 18.5469V15.3183H5.82276Z" fill="#0052B4"/>
            <path d="M12.9127 16.2288V22.4783H1.64258L12.9127 16.2288Z" fill="#0052B4"/>
            <path d="M9.26146 15.3183L0 20.4536V22.4782L12.9131 15.3183H9.26146Z" fill="#D80027"/>
            <path d="M7.61933 7.68113L0 3.45636V7.68113H7.61933Z" fill="#0052B4"/>
            <path d="M12.9131 7.68114L0 0.521233V2.54588L9.26146 7.68114H12.9131Z" fill="#0052B4"/>
            <path d="M3.43951 0.521225L12.913 5.77447V0.521225H3.43951Z" fill="#0052B4"/>
            <path d="M12.9131 7.68114L0 0.521233V2.54588L9.26146 7.68114H12.9131Z" fill="#F0F0F0"/>
            <path d="M12.9131 7.68114L0 0.521233V2.54588L9.26146 7.68114H12.9131Z" fill="#D80027"/>
            <path d="M27.1768 7.68155L32.9995 4.45296V7.68155H27.1768Z" fill="#0052B4"/>
            <path d="M20.0869 6.7708V0.521317H31.357L20.0869 6.7708Z" fill="#0052B4"/>
            <path d="M23.7385 7.68114L33 2.54588V0.521233L20.0869 7.68114H23.7385Z" fill="#D80027"/>
            </g>
            <defs>
            <clipPath id="clip0_1_574">
            <rect y="0.5" width="33" height="22" rx="2" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    )
}

export const MarketsIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.083 3.49992H9.9163C9.9163 1.86659 8.63297 0.583252 6.99963 0.583252C5.3663 0.583252 4.08297 1.86659 4.08297 3.49992H2.9163C2.27463 3.49992 1.74963 4.02492 1.74963 4.66658V11.6666C1.74963 12.3083 2.27463 12.8333 2.9163 12.8333H11.083C11.7246 12.8333 12.2496 12.3083 12.2496 11.6666V4.66658C12.2496 4.02492 11.7246 3.49992 11.083 3.49992ZM6.99963 1.74992C7.9913 1.74992 8.74963 2.50825 8.74963 3.49992H5.24963C5.24963 2.50825 6.00797 1.74992 6.99963 1.74992ZM11.083 11.6666H2.9163V4.66658H11.083V11.6666ZM6.99963 6.99992C6.00797 6.99992 5.24963 6.24159 5.24963 5.24992H4.08297C4.08297 6.88325 5.3663 8.16658 6.99963 8.16658C8.63297 8.16658 9.9163 6.88325 9.9163 5.24992H8.74963C8.74963 6.24159 7.9913 6.99992 6.99963 6.99992Z" fill="#9CA6B8"/>
    </svg>

)

export const PlatFormIcon = () => (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.38156 0.4894L11.6702 3.55469L6.3843 6.62225L1.09081 3.55452L6.38156 0.4894ZM0.423444 4.68078L5.74592 7.74975V8.78145L0.423444 5.71248V4.68078ZM12.3115 5.71624L7.0456 8.77582V7.74882L12.3115 4.68924V5.71624Z" stroke="#9CA6B8" strokeWidth="0.846887"/>
    </svg>

)

export const PromotionsIcon = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_640)">
    <path d="M6.26323 8.22003L6.81122 8.76802L5.79353 9.7857H9.00316V10.5685H5.79353L6.81122 11.5862L6.26323 12.1342L4.30614 10.1771L6.26323 8.22003ZM2.84302 9.12929L5.97045 6.00186L6.52431 6.55533L3.39687 9.68276L2.84302 9.12929Z" fill="#9CA6B8"/>
    <path d="M9.00457 8.61136H8.22174V6.65427C8.22174 6.53684 8.26088 6.45856 8.33916 6.38027L9.94398 4.77546C10.6094 4.11005 10.9617 3.20978 10.9617 2.27038V1.56583H10.218C9.27857 1.56583 8.3783 1.9181 7.71289 2.58351L6.10808 4.18833C6.06894 4.26661 5.99065 4.30576 5.87323 4.30576H2.93759L1.9199 5.59744L3.99442 5.91057L3.87699 6.69341L1.13706 6.30199C0.980496 6.26285 0.86307 6.18456 0.823928 6.06714C0.784786 5.94971 0.784786 5.79315 0.86307 5.67572L2.42874 3.71863C2.50703 3.56206 2.62445 3.52292 2.74188 3.52292H5.71666L7.20405 2.03553C7.98689 1.21355 9.08286 0.78299 10.218 0.78299H10.9617C11.3922 0.78299 11.7445 1.13527 11.7445 1.56583V2.30952C11.7445 3.44463 11.3139 4.54061 10.492 5.36259L9.00457 6.81083V8.61136Z" fill="#9CA6B8"/>
    </g>
    <defs>
    <clipPath id="clip0_1_640">
    <rect width="12.5254" height="12.5254" fill="white"/>
    </clipPath>
    </defs>
    </svg>

)

export const PLXIcon = () => (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.94562 3.64113L8.45571 0.220276H11.9468L8.45429 4.88553L11.9483 9.54014H8.44364L5.94562 6.13135V3.64113Z" fill="white"/>
    <path d="M3.49266 0.220245L6.00275 3.6411V6.13132L3.50472 9.54011H9.87053e-05L3.49407 4.8855L0.00151873 0.220245H3.49266Z" fill="#9CA6B8"/>
    <rect x="1.68445" y="3.67242" width="4.32363" height="2.48448" fill="#14161F"/>
    </svg>

)

export const CompanyIcon = () => (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.4998 6.25006H6.66646V7.08339H7.4998M7.4998 4.58339H6.66646V5.41673H7.4998M8.33313 7.91673H4.9998V7.08339H5.83313V6.25006H4.9998V5.41673H5.83313V4.58339H4.9998V3.75006H8.33313M4.16646 2.91673H3.33313V2.08339H4.16646M4.16646 4.58339H3.33313V3.75006H4.16646M4.16646 6.25006H3.33313V5.41673H4.16646M4.16646 7.91673H3.33313V7.08339H4.16646M2.4998 2.91673H1.66646V2.08339H2.4998M2.4998 4.58339H1.66646V3.75006H2.4998M2.4998 6.25006H1.66646V5.41673H2.4998M2.4998 7.91673H1.66646V7.08339H2.4998M4.9998 2.91673V1.25006H0.83313V8.75006H9.16646V2.91673H4.9998Z" fill="#9CA6B8"/>
</svg>

)